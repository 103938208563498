<template>
  <div class="faq mainBox flex-start">
    <div class="faq-left">
      <img src="@/assets/img/FAQ.png" />
      <div class="cont">
        <!-- 此处不用翻译 -->
        <div class="text">常见问题解答</div>
        <div class="text-tip">FREQUENTLY AKED QUESTIONS</div>
        <!-- <el-button class="form-contact" plain round>
          {{ $t('faq_2') }}
          <i class="el-icon-arrow-right el-icon--right"></i>
        </el-button> -->
      </div>
    </div>
    <div class="faq-right">
      <div class="tit">{{ $t('faq_3') }}</div>
      <div class="issue-list">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="issue-list-tit">
                这是一个新闻消息，这这是一个新这这是一个新这这是一个新闻消息，这这是一个新
              </div>
            </template>
            <div>
              与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
            </div>
            <div>
              在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
            </div>
          </el-collapse-item>
          <el-collapse-item title="这是一个新闻消息" name="2">
            <div>
              控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；
            </div>
            <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
          </el-collapse-item>
          <el-collapse-item title="这是一个新闻消息" name="3">
            <div>简化流程：设计简洁直观的操作流程；</div>
            <div>
              清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；
            </div>
            <div>
              帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
            </div>
          </el-collapse-item>
          <el-collapse-item title="这是一个新闻消息" name="4">
            <div>
              用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
            </div>
            <div>
              结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 1,
    }
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 640 + 'px'
    },
  },
}
</script>

<style scoped lang="scss">
.faq {
  padding-top: 50px;
  padding-bottom: 40px;
}
.faq-left {
  position: relative;
  margin-right: 40px;
  img {
    width: 560px;
  }
  .cont {
    position: absolute;
    top: 120px;
    right: 60px;
    color: #ffffff;
    .text {
      font-size: 50px;
    }
    .text-tip {
      font-size: 18px;
      margin-top: 8px;
    }
    .form-contact {
      border-color: #ffffff;
      background-color: initial;
      margin-top: 30px;
    }
  }
}
.faq-right {
  flex: 1;
  .tit {
    font-size: 14px;
    font-weight: 800;
    color: #333333;
    margin-bottom: 10px;
  }
  .issue-list-tit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 560px;
  }
  /deep/ .el-collapse {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      background-color: initial;
      font-size: 12px;
      color: #666666;
    }
    .el-collapse-item__content {
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
